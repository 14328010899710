import React, { useEffect } from 'react';
import HeaderBackground from "../../components/HeaderBackground";
import { Container } from "react-bootstrap";
import Header from "../../components/Header";
import FooterBackground from "../../components/FooterBackground";
import parse from "html-react-parser";
import MetaTags from 'react-meta-tags';

import './style.scss';

const General = ({ type, languageText, language, handleLanguageChange }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <HeaderBackground color="black" />
      <Container>
        <Header handleLanguageChange={handleLanguageChange}
          language={language}
          text={languageText.header}
          color="black"
        />
        {languageText.pages[type] && (
          <MetaTags htmlAttributes={{lang: languageText.language}}>
            <meta charSet="utf-8" />
            <title>{languageText.pages[type].title}</title>
            <meta name="description" content={languageText.pages[type].description} />
            <meta name="keywords" content={languageText.pages[type].seoword} />
        </MetaTags>
        )}
        {languageText.pages[type] && (<div className='General'>{parse(languageText.pages[type].body)}</div>)}
      </Container>
      <FooterBackground text={languageText.footer} />
    </>

  );
};

export default General;
