import React from "react";
import NotFoundDesktop from "../NotFoundDesktop";
import NotFoundMobile from "../NotFoundMobile";

class NotFoundContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            size: ""
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({size: window.innerWidth <= 991 ? "mobile" : "desktop"});
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }
    render() {
        const { size } = this.state;
        return (
            <>
                {
                    size === "mobile"
                        ? <NotFoundMobile text={this.props.text} screenSize={window.innerWidth} history={this.props.history} />
                        : <NotFoundDesktop text={this.props.text} history={this.props.history} />
                }
            </>
        );
    }
}

export default NotFoundContainer;