import React from "react";
import MetaTags from "react-meta-tags";
import HeaderBackground from "../../components/HeaderBackground";
import Header from "../../components/Header";
import {Container, Row, Col,} from "react-bootstrap";
import FooterBackground from "../../components/FooterBackground";

import OfficialSponsors from "../../components/EUDigitalKitProgram/officialSponsors";
import renderHTML from "react-render-html";
import {Parallax} from "react-scroll-parallax";

import EUDigitalKitProgramStyle from "../../styles/EUDigitalKitProgram.module.scss"

class EUDigitalKitProgramPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meta: {
                title: "",
                description: ""
            },
            size: "desktop",
            reload: true
        };
    }
    componentDidMount() {
        window.scrollTo(0,0);
        const reloadCount = sessionStorage.getItem('reloadCount');
        if(reloadCount < 2) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));
            window.location.reload();
        } else {
            sessionStorage.removeItem('reloadCount');
        }
    }
    componentWillUnmount() {
        //this.props.handleLanguageChange("ES"); Esto cuelga la página
    }
    render() {
        const { meta } = this.state;
        const { size } = this.state;
        const leftPath = "/images/"+size+"-work-hero-left.png";
        const rightPath = "/images/"+size+"-work-hero-right.png";
        return (
            <>
                <MetaTags>
                    <title>
                        {meta.title}
                    </title>
                    <meta name="description" content={meta.description}/>
                </MetaTags>
                <HeaderBackground color={"white"}/>
                <Header handleLanguageChange={this.props.handleLanguageChange}
                        language={this.props.language}
                        text={this.props.languageText.header}
                        color={"white"}
                />
                <Container className={`${EUDigitalKitProgramStyle.pageWrapper}`}>
                    <OfficialSponsors></OfficialSponsors>
                    <Row>
                        <Col>
                            <h1 className='nn-H3-Light'>{this.props.languageText.pages.EUDigitalProgram.title1}<b
                                className={'nn-H3-Bold'}>{this.props.languageText.pages.EUDigitalProgram.title2}</b>
                                <div className={`${EUDigitalKitProgramStyle.textLine}`}/>
                            </h1>
                        </Col>
                    </Row>
                    <section>
                        <Row>
                            <Col className={`${EUDigitalKitProgramStyle.categoryTitle}`}>{this.props.languageText.pages.EUDigitalProgram.category1.title}</Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col className={`${EUDigitalKitProgramStyle.first}`}>
                                <div className={`${EUDigitalKitProgramStyle.solutionTitle}`}>{this.props.languageText.pages.EUDigitalProgram.category1.solution1.title}</div>
                                <div className={`${EUDigitalKitProgramStyle.solutionDesc}`}>{this.props.languageText.pages.EUDigitalProgram.category1.solution1.desc}</div>
                                <div>{renderHTML(this.props.languageText.pages.EUDigitalProgram.category1.solution1.price)}</div>
                            </Col>
                            <Col md={1}></Col>
                            <Col>
                                <div className={`${EUDigitalKitProgramStyle.solutionTitle}`}>{this.props.languageText.pages.EUDigitalProgram.category1.solution2.title}</div>
                                <div className={`${EUDigitalKitProgramStyle.solutionDesc}`}>{this.props.languageText.pages.EUDigitalProgram.category1.solution2.desc}</div>
                                <div>{renderHTML(this.props.languageText.pages.EUDigitalProgram.category1.solution2.price)}</div>
                            </Col>
                        </Row>
                    </section>
                </Container>
                <section className={`${EUDigitalKitProgramStyle.gray}`}>
                    <Container>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className={`${EUDigitalKitProgramStyle.categoryTitle}`}>{this.props.languageText.pages.EUDigitalProgram.category2.title}</Col>
                                </Row>
                                <Row className={`${EUDigitalKitProgramStyle.firstRow}`}>
                                    <Col md={2}></Col>
                                    <Col md>
                                        <div className={`${EUDigitalKitProgramStyle.solutionTitle}`}>{this.props.languageText.pages.EUDigitalProgram.category2.solution1.title}</div>
                                        <div className={`${EUDigitalKitProgramStyle.solutionDesc}`}>{this.props.languageText.pages.EUDigitalProgram.category2.solution1.desc}</div>
                                        <div>{renderHTML(this.props.languageText.pages.EUDigitalProgram.category2.solution1.price)}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={`${EUDigitalKitProgramStyle.categoryTitle}`}>{this.props.languageText.pages.EUDigitalProgram.category3.title}</Col>
                                </Row>
                                <Row>
                                    <Col md={2}></Col>
                                    <Col md>
                                        <div className={`${EUDigitalKitProgramStyle.solutionTitle}`}>{this.props.languageText.pages.EUDigitalProgram.category3.solution1.title}</div>
                                        <div className={`${EUDigitalKitProgramStyle.solutionDesc}`}>{this.props.languageText.pages.EUDigitalProgram.category3.solution1.desc}</div>
                                        <div>{renderHTML(this.props.languageText.pages.EUDigitalProgram.category3.solution1.price)}</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="d-none d-md-block">
                                <Row>
                                    <Col></Col>
                                    <Col>
                                <div className={`${EUDigitalKitProgramStyle.parallaxWrapper}`}>
                                    <Parallax className={`${EUDigitalKitProgramStyle.parallaxImage}`} y={[30, -10]} x={[-30,-30]}>
                                        <img src={leftPath} alt="Mobile" />
                                    </Parallax>
                                    <Parallax className={`${EUDigitalKitProgramStyle.parallaxImage}`} y={[0,40]} x={[50,50]}>
                                        <img src={rightPath} alt="Mobile" />
                                    </Parallax>
                                </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Container>
                    <section>
                        <Row>
                            <Col md>
                                <Row>
                                    <Col className={`${EUDigitalKitProgramStyle.categoryTitle}`}>{this.props.languageText.pages.EUDigitalProgram.category4.title}</Col>
                                </Row>
                                <Row>
                                    <Col md={2}></Col>
                                    <Col className={`${EUDigitalKitProgramStyle.first}`}>
                                        <div className={`${EUDigitalKitProgramStyle.solutionTitle}`}>{this.props.languageText.pages.EUDigitalProgram.category4.solution1.title}</div>
                                        <div className={`${EUDigitalKitProgramStyle.solutionDesc}`}>{this.props.languageText.pages.EUDigitalProgram.category4.solution1.desc}</div>
                                        <div>{renderHTML(this.props.languageText.pages.EUDigitalProgram.category4.solution1.price)}</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md>
                                <Row>
                                    <Col className={`${EUDigitalKitProgramStyle.categoryTitle}`}>{this.props.languageText.pages.EUDigitalProgram.category5.title}</Col>
                                </Row>
                                <Row>
                                    <Col md={2}></Col>
                                    <Col className={`${EUDigitalKitProgramStyle.first}`}>
                                        <div className={`${EUDigitalKitProgramStyle.solutionTitle}`}>{this.props.languageText.pages.EUDigitalProgram.category5.solution1.title}</div>
                                        <div className={`${EUDigitalKitProgramStyle.solutionDesc}`}>{this.props.languageText.pages.EUDigitalProgram.category5.solution1.desc}</div>
                                        <div>{renderHTML(this.props.languageText.pages.EUDigitalProgram.category5.solution1.price)}</div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </section>
                </Container>
                <section className={`${EUDigitalKitProgramStyle.gray}`}>
                    <Container>
                        <Row>
                            <Col className={`${EUDigitalKitProgramStyle.categoryTitle}`}>{this.props.languageText.pages.EUDigitalProgram.category6.title}</Col>
                        </Row>
                        <Row className={`${EUDigitalKitProgramStyle.firstRow}`}>
                            <Col md={1}></Col>
                            <Col md={5}>
                                <div className={`${EUDigitalKitProgramStyle.solutionTitle}`}>{this.props.languageText.pages.EUDigitalProgram.category6.solution1.title}</div>
                                <div className={`${EUDigitalKitProgramStyle.solutionDesc}`}>{this.props.languageText.pages.EUDigitalProgram.category6.solution1.desc}</div>
                                <div>{renderHTML(this.props.languageText.pages.EUDigitalProgram.category6.solution1.price)}</div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <FooterBackground text={this.props.languageText.footer} noMargin={true}/>
            </>
        );
    }
}

export default EUDigitalKitProgramPage;
