import React from "react";
import {Row, Col} from "react-bootstrap";
import ServiceRecoveryStyle from "../../styles/ServiceRecovery.module.scss";
import '../../styles/App.scss';


class ServiceRecovery extends React.Component {
    render() {
        return (
            <>
                    {this.props.services.map( (im, index) => {
                        return (
                            <Row className={ServiceRecoveryStyle.serviceSpace} key={index}>
                                <Col xs={12} className={"px-0"}>
                                    {im}
                                </Col>
                            </Row>
                        )}
                    )}
            </>

        );
    }
}

export default ServiceRecovery;
