import React, { useState, useEffect } from "react";
// import HeaderBackground from "../../components/HeaderBackground";
import Header from "../../components/Header";
import FooterBackground from "../../components/FooterBackground";
import ContentVideoMain from "../../components/ContentVideoMain";
import Concepts from "../../components/Concepts";
import TechCarousel from "../../components/TechCarousel";
import ProcessContainer from "../../components/ProcessContainer";
import ContentJobSummary from "../../components/ContentJobSummary";
import ValuesContainer from "../../components/ValuesContainer";
import ContactForm from "../../components/ContactForm";
import SearchTalent from "../../components/SearchTalent";
import MetaTags from "react-meta-tags";

const MainPage = ({ languageText, language, history, handleLanguageChange }) => {
  const [meta, setMeta] = useState({
    title: '',
    description: ''
  });

  useEffect(() => {
    switch (language) {
      case "ES":
        setMeta({
          title: "Consultoría It para Empresas | Phoenix Connection",
          description: "Soluciones tecnológicas adaptadas a las necesidades de tu empresa. Ofrecemos procesos de digitalización, servicios de diseño y marketing digital ¡Llámanos!"
        });
        break;
      default:
        setMeta({
          title: "IT Consulting for Business | Phoenix Connection",
          description: "Technological solutions adapted to the needs of your company. We offer you digitization processes, design services and digital marketing. Call Us!"

        });
        break;
    };
  }, [language])

  return (
    <>
      <MetaTags>
        <title>
          {meta.title}
        </title>
        <meta name="description" content={meta.description} />
      </MetaTags>
      {/* <HeaderBackground color={"white"} /> */}
      <Header
        handleLanguageChange={handleLanguageChange}
        language={language}
        text={languageText.header}
        color={"white"}
      />
      <ContentVideoMain text={languageText.mainVideo} />
      <Concepts text={languageText.concepts} />
      <ProcessContainer text={languageText.descriptive} />
      <TechCarousel text={languageText.techCarousel} />
      <ContentJobSummary
        text={languageText.jobSummaryContainer}
        jobs={languageText.jobsSummary}
        history={history}
      />
      <ValuesContainer text={languageText.values} />
      <ContactForm
        text={languageText.contactForm}
        language={language}
        legalAdvise={languageText.legalAdvise}
        translation={languageText}
        history={history}
      />
      <SearchTalent text={languageText.searchTalent} />
      <FooterBackground text={languageText.footer} />
    </>
  );
}
export default MainPage;
