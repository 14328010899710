import React from "react";
import footerStyles from "../../styles/Footer.module.scss";
import Footer from "../Footer";
import { Container } from "react-bootstrap";

function FooterBackground(props) {
    return (
        <>
            <div className={footerStyles.backgroundBlack}>
                <Container className={`${footerStyles.topSpace} ${props.noMargin ? footerStyles.noMargin : ""}`}>
                    <Footer text={props.text} />
                </Container>
            </div>

        </>
    );
}

export default FooterBackground;
