import React from "react";
import { ControlBar, Player } from 'video-react';
import { Col, Row } from "react-bootstrap";
import videoStyles from "../../styles/VideoMainPage.module.scss";

class VideoMainPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            width: 0,
            height: 0,
            srcvideo: ""
        };
    }

    async componentDidMount() {
        await this.player.load();
        this.player.play();
    }

    render() {
        return (
            <div className='VideoMainPage'>
                <Row className={videoStyles.videoContainer}>
                    <Col xs={12} className={videoStyles.video + " fullWidthContainer mx-auto " + videoStyles.tabletSpecific}>
                        <Player ref={player => {
                            this.player = player;
                        }} autoPlay={true} muted={true} fluid={false} height={this.props.height} width={this.props.width} playsInline={true}>
                            <ControlBar disableCompletely={true} />
                            <source src={this.props.src} />
                        </Player>
                    </Col>
                </Row>
            </div>
        );
    }

}

export default VideoMainPage;
