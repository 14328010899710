import React from "react";
import Values from '../Values';

function ValuesContainer(props) {
    return (
        <div className="container">
            <Values text={props.text} />
        </div>
    );
}

export default ValuesContainer;
