import React from "react";
import HeaderBackground from "../../components/HeaderBackground";
import { Container } from "react-bootstrap";
import Header from "../../components/Header";
import FooterBackground from "../../components/FooterBackground";
import WorkTitle from "../../components/WorkTitle";
import WorkContainer from "../../components/WorkContainer";
import ContactForm from "../../components/ContactForm";
import SearchTalent from "../../components/SearchTalent";
import MetaTags from "react-meta-tags";

class WorkPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meta: {
        title: "",
        description: ""
      }
    };
  }

  componentDidMount() {
    switch (this.props.language) {
      case "EN":
        this.setState({
          meta: {
            title: "IT Projects | Phoenix Connection",
            description: "Enter to discover some of our works and projects most loved by our team. We show you a sample of what we are most passionate about"
          }
        });
        break;
      case "ES":
        this.setState({
          meta: {
            title: "Trabajos y Proyectos IT de Phoenix Connection",
            description: "Te mostramos algunos de nuestros trabajos y retos. En ellos podrás ver la punta del iceberg de algunos de nuestros proyectos más queridos."
          }
        });
        break;
      default:
    }
  }
  render() {
    const { meta } = this.state;
    return (
      <>
        <MetaTags>
          <title>
            {meta.title}
          </title>
          <meta name="description" content={meta.description} />
        </MetaTags>
        <HeaderBackground color={"white"} />
        <Container>
          <Header handleLanguageChange={this.props.handleLanguageChange}
            language={this.props.language}
            text={this.props.languageText.header}
            color={"white"}
          />
          <WorkTitle text={this.props.languageText.workTitle} language={this.props.language} />
          <WorkContainer
            text={this.props.languageText.workContainer}
            jobs={this.props.languageText.job}
            workTitles={this.props.languageText.workTitles}
          />
          <ContactForm
            text={this.props.languageText.contactForm}
            language={this.props.language}
            legalAdvise={this.props.languageText.legalAdvise}
            translation={this.props.languageText}
            history={this.props.history}
          />
          <SearchTalent text={this.props.languageText.searchTalent} />
        </Container>
        <FooterBackground text={this.props.languageText.footer} />
      </>
    );
  }
}

export default WorkPage;
