import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import MRStyles from '../../styles/MessageReceived.module.scss';
import Lottie from "react-lottie";
import animationData from '../../lotties/Astronaut-game.json';
import '../../styles/miniGame.scss';

class MessageReceived extends React.Component {
   componentDidMount() {
        const script = document.createElement("script");
        script.src = "/js/miniGame.js";
        script.async = true;
        document.body.appendChild(script);
        window.scrollTo(0,0);
        script.src = "/js/messageReceivedTag.js";
        script.async = true;
        document.body.appendChild(script);
    }

    scrollToGameRef = () => {
        this.gameRef.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
        })
    }

    handleBack = () => {
        this.props.history.back();
    }

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData
        };

        const desktopLayout = (
            <>
                <Col xs={12} md={12} lg={6}>
                    <Lottie options={defaultOptions}
                            height={400}
                            width={400}/>
                </Col>
                <Col xs={12} md={12} lg={6}>
                    <h1 className={MRStyles.title}>
                        <span className={MRStyles.boldTitle}>{this.props.text.messageReceived}</span><br />
                        {this.props.text.getBack}
                        <hr />
                    </h1>
                    <br />
                    <p className={MRStyles.subtitle}>
                        {this.props.text.subTitle}
                    </p>
                    <br />
                    <div className={MRStyles.buttonBox}>
                        <Button className="phoenix-btn" onClick={() => this.handleBack()}>
                            {this.props.text.takeMeBack}
                        </Button>
                        <Button className="phoenix-btn-inverted" onClick={() => this.scrollToGameRef()}>
                            {this.props.text.playGame}
                        </Button>
                    </div>
                </Col>
            </>
        );

        const portableLayout = (
            <>
                <Col md={8} xs={12} className="d-lg-none text-right offset-md-4" >
                    <Lottie options={defaultOptions}
                            height={this.props.screenSize >= 480 ? 400 : 182}
                            width={this.props.screenSize >= 480 ? 400 : 340}/>
                </Col>
                <Col xs={12} md={12} lg={6}>
                    <h2 className={MRStyles.title}>
                        <span className={MRStyles.boldTitle}>{this.props.text.messageReceived}</span><br />
                        {this.props.text.getBack}
                        <hr />
                    </h2>
                    <p className={MRStyles.subtitle}>
                        <span>{this.props.text.subTitle1}</span>
                        <span>{this.props.text.subTitle2}</span>
                    </p>
                    <div className={MRStyles.buttonBox}>
                            <button className="phoenix-btn" onClick={this.handleBack}>
                                {this.props.text.takeMeBack}
                            </button>
                            <button className="phoenix-btn-inverted" onClick={this.scrollToGameRef}>
                                {this.props.text.playGame}
                            </button>
                    </div>
                </Col>
                <Col lg={6} className="d-none d-md-none d-sm-none  d-lg-block">
                    <Lottie options={defaultOptions}
                            height={this.props.screenSize >= 480 ? 400 : 350}
                            width={this.props.screenSize >= 480 ? 400 : 350}/>
                </Col>
            </>
        );
        return(
            <>
                <Row className={MRStyles.MessageReceivedContainer}>
                    {
                        this.props.screenSize >= 991 ? desktopLayout : portableLayout
                    }
                </Row>
                <div ref={(ref) => this.gameRef=ref} className="miniGameContainer">
                    <div>
                        <h2 className="heading">{this.props.text.hitHideTitle}</h2>
                        <Row>
                            <Col xs={12} md={6} lg={{ span: 4, offset: 4 }} >
                                <p className="gameDescription">
                                    <span>{this.props.text.gameDescription1}</span>
                                    <span>{this.props.text.gameDescription2}</span>
                                    </p>
                            </Col>
                            <Col >
                                <h3 className="scoreTitle">
                                    {this.props.text.score}:
                                    <span>0/27</span>
                                </h3>
                            </Col>
                        </Row>
                        <div className="game-wrapper">
                            <div className="game-wrapper__hole">
                            </div>
                            <div className="game-wrapper__hole"></div>
                            <div className="game-wrapper__hole"></div>
                            <div className="game-wrapper__hole"></div>
                            <div className="game-wrapper__hole"></div>
                            <div className="game-wrapper__hole"></div>
                            <div className="game-wrapper__hole"></div>
                            <div className="game-wrapper__hole"></div>
                            <div className="game-wrapper__hole"></div>
                        </div>
                        <div className="scoreboard">
                            <div className="score">
                                <h3>{this.props.text.gameOver}</h3>
                                <button className="restart">{this.props.text.restart}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default MessageReceived;
