import React from "react";
import jobSummaryStyle from "../../styles/JobSummary.module.scss";
import jobSummaryStyleTablet from "../../styles/JobSummaryTablet.module.scss";
import { Player, ControlBar } from 'video-react';
import { Col } from "react-bootstrap";
import '../../styles/App.scss';
import { isTablet, isBrowser } from "react-device-detect";

class JobSummaryTablet extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      playerSource: this.props.info.video
    }
    this.play = this.play.bind(this);
    this.click = this.click.bind(this);
  }

  componentDidMount() {
    this.player.load();
  }

  play() {
    this.player.play();
  }

  click() {
  }

  render() {
    const componentePar = (
      <>
        <Col md={{ span: 6, offset: 6 }} className={jobSummaryStyleTablet.textRight}>

          <p className={`${jobSummaryStyle.titleWork} nn-Subheading`}>
            {this.props.info.jobTitle}
          </p>
          <h3 className={`${jobSummaryStyleTablet.subTitleWork_TabletMov} nn-H3-regular`}>
            {this.props.info.jobSubtitle}
          </h3>
          <p className={`${jobSummaryStyleTablet.descriptionWork_TabletMov_Par} nn-Body`}>
            {this.props.info.description}
          </p>
          <a href={`work#${this.props.orderItem}linkWork`} className={`link nn-links`}>
            {this.props.info.link}
          </a>
        </Col>
        <Col md={{ span: 8 }} className={jobSummaryStyle.spaceWorks_Par}>
          <div className={`${isTablet || isBrowser ? '' : 'hidden'} ${jobSummaryStyleTablet.workTabletPlayer} job-video`} onMouseOver={this.play}>
            <Player
              ref={player => {
                this.player = player;
              }}
              fluid={false}
              height={434}
              width={572}
            >
              <ControlBar disableCompletely={true} />
              <source src={this.props.info.video} />
            </Player>
          </div>
          <img
            className={`${jobSummaryStyleTablet.workTabletImage} ${jobSummaryStyleTablet.imageRight}`}
            src={this.props.info.image}
            alt={this.props.info.jobTitle}
            width={312}
          />
        </Col>
      </>
    );
    const componenteImpar = (
      <>
        <Col md={12} className={jobSummaryStyleTablet.spacelink}>
          <p className={`${jobSummaryStyle.titleWork} nn-Subheading`}>
            {this.props.info.jobTitle}
          </p>
          <h3 className={`${jobSummaryStyle.subTitleWork} nn-H3-regular`}>
            {this.props.info.jobSubtitle}
          </h3>
          <p className={`${jobSummaryStyleTablet.descriptionWork_TabletMov_Impar} nn-Body`}>
            {this.props.info.description}
          </p>
          <a href={`work#${this.props.orderItem}linkWork`} className={`link nn-links`}>
            {this.props.info.link}
          </a>
        </Col>
        <Col md={{ offset: 2 }} className={jobSummaryStyle.spaceWorks}>
          <div className={`${isTablet || isBrowser ? '' : 'hidden'} ${jobSummaryStyleTablet.workTabletPlayer} job-video`}
            onMouseOver={this.play}
            style={{ float: "right" }}
          >
            <Player
              ref={player => {
                this.player = player;
              }}
              fluid={false}
              height={434}
              width={572}
            >
              <ControlBar disableCompletely={true} />
              <source src={this.props.info.video} />
            </Player>
          </div>
          <img
            className={`${jobSummaryStyleTablet.workTabletImage}`}
            src={this.props.info.image}
            alt={this.props.info.jobTitle}
            width={312}
          />
        </Col>
      </>
    );
    return (
      <>
        {this.props.parImpar ? componentePar : componenteImpar}
      </>
    );
  }
}

export default JobSummaryTablet;
