import React from "react";
import HeaderBackground from "../../components/HeaderBackground";
import {Container} from "react-bootstrap";
import Header from "../../components/Header";
import NotFoundContainer from "../../components/NotFoundContainer";
import FooterBackground from "../../components/FooterBackground";

class NotFoundPage extends React.Component {
    componentDidMount() {
        switch (this.props.language) {
            case "EN":
                document.title = "Not found | Phoenix Connection";
                break;
            case "ES":
                document.title = "Página no encontrada | Phoenix Connection";
                break;
            default:
                document.title = "Not found | Phoenix Connection";
                break;
        }
    }
    render() {
            return (
                <>
                    <HeaderBackground color="black" />
                    <Container>
                        <Header handleLanguageChange={this.props.handleLanguageChange}
                                language={this.props.language}
                                text={this.props.languageText.header}
                                color="black"
                        />
                        <NotFoundContainer text={this.props.languageText.notFound} history={this.props.history} />
                    </Container>
                    <FooterBackground text={this.props.languageText.footer} />
                </>
            );
    }
}

export default NotFoundPage;
