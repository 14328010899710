import React from "react";
import JobSummary from "../JobSummary";
import JobSummaryTablet from "../JobSummaryTablet";
import jobSummaryStyle from "../../styles/JobSummary.module.scss";
import contentSummaryStyle from "../../styles/ContentJobSummary.module.scss";
import { Row, Col, Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class ContentJobSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: ""
    };
  }

  parImpar(number) {
    return (number % 2 === 0);
  }


  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ size: window.innerWidth <= 991 ? "tablet" : "desktop" });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  handleWorkLink = () => {
    this.props.history.push('work');
  }

  render() {
    const { jobs } = this.props;
    const { size } = this.state;

    return (
      <div className={"fullWidthContainer lightGreyBackground jobSummaryContainer"} id={"jobs"}>
        <Container className='jobContainer'>
          <Row className={`${jobSummaryStyle.titleRow} padRight padLeft`}>
            <Col lg={12} xs={12} className={jobSummaryStyle.topSpace}>
              <h2 className="nn-H2-Bold">
                <span className={`${jobSummaryStyle.lightTitle} nn-H2-Light`}>{this.props.text.our}</span> {this.props.text.work}
                <div className={jobSummaryStyle.ourWorkBox} />
              </h2>
            </Col>
          </Row>
          <Row className={contentSummaryStyle.spaceSummary}>
            {jobs.map((im, index) => {
              if (size === "tablet") {
                return <JobSummaryTablet key={index} parImpar={this.parImpar(index + 1)} info={im} orderItem={index + 1} screenSize={window.innerWidth} />
              }
              return <JobSummary key={index} parImpar={this.parImpar(index + 1)} info={im} orderItem={index + 1} />
            }
            )}
          </Row>
          <Row>
            {size === 'tablet' &&
              <div style={{ paddingLeft: 24, paddingRight: 24, width: '100%' }}>
                <button className={"phoenix-btn"} style={{ width: '100%' }} onClick={this.handleWorkLink}  >
                  {this.props.text.buttonText}
                </button>
              </div>
            }
            {size !== 'tablet' &&
              <Col xs={12} className={contentSummaryStyle.spaceButton}>
                <button className={"phoenix-btn"} onClick={this.handleWorkLink} >
                  {this.props.text.buttonText}
                </button>
              </Col>
            }
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(ContentJobSummary);
