import React from "react";
import { Row, Col } from "react-bootstrap";
import notFoundStyles from "../../styles/NotFound.module.scss";
import {withRouter, Link} from "react-router-dom";

function NotFoundDesktop(props) {

    return(
        <Row className={notFoundStyles.notFoundContainer}>
            <Col lg="6">
                <Col xs="12" className={notFoundStyles.titleContainer}>
                    <h2 className={notFoundStyles.title}>
                        <span className={notFoundStyles.boldTitle}>{props.text.boldTitle}</span>{props.text.title}
                        <hr />
                    </h2>
                </Col>
                <Col xs="12">
                    <p className={notFoundStyles.subTitle}>
                        {props.text.subTitle}
                    </p>
                </Col>
                <Col xs="12">
                    <div className={notFoundStyles.linksArea}>
                        <Link to="/">{props.text.homeLink}</Link>
                        <Link to={{
                            pathname: "/",
                            hash: "process"
                        }}>{props.text.processLink}
                        </Link>
                        <Link to="/work">{props.text.workLink}</Link>
                        <button className="phoenix-btn" onClick={() => handleBack(props.history)}>
                            {props.text.backButtonText}
                        </button>
                    </div>
                </Col>
            </Col>
            <Col lg="6"> 
                <span className={notFoundStyles.lottie}>
                    <img alt="404" src="/images/404.svg"
                            height={355}
                            width={window.innerWidth <= 1024 ? 456 : 556 }/>
                </span>
            </Col>
        </Row>
    );
}

const handleBack = (history) => {
    history.goBack();
}

export default withRouter(NotFoundDesktop);
