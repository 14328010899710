import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import WorkMob from "../WorkMob";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import WorkContainerStyles from '../../styles/WorkContainer.module.scss';
import WorkDesktop from "../WorkDesktop";
import { isBrowser, isTablet } from 'react-device-detect';
import WorkDesktopStyle from "../../styles/WorkDesktop.module.scss";

/*
*
* Expected props:
*
* text={languageEN.workContainer}
* jobs={languageEN.job}
* workTitles={languageEN.workTitles}
*
* */

class WorkContainer extends React.Component {
  componentDidMount() {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });
  }

  parImpar(number) {
    return (number % 2 === 0);
  }
  render() {
    return (
      <div className={WorkContainerStyles.worksContainer + " fullWidthContainer lightGreyBackground"}>
        <Container className={WorkDesktopStyle.desktopTitleContainer}>
          <Row>
            <Col xs={12} className={WorkContainerStyles.selectedContainer}>
              <h1 className={`nn-H3-Light ${WorkContainerStyles.selectedWork} `}>{this.props.text.selected}{ }
                <span className={`${WorkContainerStyles.boldTitle} nn-H3-Bold`}> {this.props.text.work}</span>
                <hr />
              </h1>
            </Col>
          </Row>
        </Container>
        {this.props.jobs.map((im, index) => {
          return (
            <Row key={index} id={`${index + 1}linkWork`}>
              <Col xs={12}>
                {
                  isBrowser || isTablet
                    ? (
                      <WorkDesktop
                        idLink={`link${index}`}
                        text={im}
                        key={index}
                        workTitles={this.props.workTitles}
                        setup={index + 1}
                        parImpar={this.parImpar(index + 1)}
                      />
                    )
                    : (
                      <WorkMob idLink={`link${index}`} text={im} key={index} workTitles={this.props.workTitles} parImpar={this.parImpar(index + 1)} />
                    )
                }
              </Col> </Row>
          )
        }
        )}
      </div>
    );
  }
}

export default WorkContainer;
