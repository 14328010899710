import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import headerStyles from "../../styles/Header.module.scss";
import ScrollSpy from "react-scrollspy";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: ""
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ size: window.innerWidth <= 550 ? "mobile" : "desktop" });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  resetProcess() {
    if (document.getElementById('conceptWrapper') !== null) {
      const box = document.getElementById('conceptWrapper');
      box.scrollLeft = 0;
    }
  }
  changeLanguage = (language) => {
    this.props.handleLanguageChange(language);
  }

  render() {
    const { size } = this.state;
    return (
      <Navbar className={`${headerStyles.menuBar} ${this.props.color === "black" ? headerStyles.blackMenu : headerStyles.whiteMenu} fixed-top`} expand="lg">
        <Container className={"headerContainer"}>
          <Navbar.Brand className="d-flex w-50 mr-auto" as={Link} to="/">
            {
              size === "mobile"
                ? <img alt="phoenix-logo" src={this.props.color === "black" ? "/logo/LOGO-ONELINE-WHITE.svg" : "/logo/LOGO-ONELINE-BLACK.svg"} />
                : <img alt="phoenix-logo" src={this.props.color === "black" ? "/logo/LOGO-LEFT-WHITE.svg" : "/logo/LOGO-LEFT-BLACK.svg"} />
            }
          </Navbar.Brand>
          <Nav.Item className={headerStyles.languageSwitchContainer}>
            <span
              className={`${this.props.color === "black" ? headerStyles.languageSwitchDark : headerStyles.languageSwitch} 
                                ${this.props.language === "EN"
                  ? this.props.color === "black"
                    ? headerStyles.activeDark
                    : headerStyles.active
                  : ''}`}
              onClick={() => this.changeLanguage("EN")}
            >
              EN
            </span>
            <span
              className={`${this.props.color === "black" ? headerStyles.languageSwitchDark : headerStyles.languageSwitch} 
                                ${this.props.color === "black" ? headerStyles.slash : headerStyles.slashDark}`}
            >
              /
            </span>
            <span
              className={`${this.props.color === "black" ? headerStyles.languageSwitchDark : headerStyles.languageSwitch} 
                                ${this.props.language === "ES"
                  ? this.props.color === "black"
                    ? headerStyles.activeDark
                    : headerStyles.active
                  : ''}`}
              onClick={() => this.changeLanguage("ES")}
            >
              ES
            </span>
          </Nav.Item>
          <Navbar.Toggle className={this.props.color === "black" ? "navbar-dark" : "navbar-light"} aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className={this.props.color === "black" ? headerStyles.navBackground : headerStyles.navBackgroundWhite} />
            <Nav id={"linkContainer"} className={`${headerStyles.linkContainer} ml-auto w-100 justify-content-end`}>
              <ScrollSpy items={['process', 'technologies', 'work', 'team', 'contact']} currentClassName="active">
                <Nav.Link className={`${headerStyles.padNav} ${headerStyles.padNavleft}`} href="https://phoenixconnection.es/#process">{this.props.text.linkProcess}<hr /></Nav.Link>
                <Nav.Link className={`${headerStyles.padNav}`} href="https://phoenixconnection.es/#technologies">{this.props.text.linkTechnologies}<hr /></Nav.Link>
                <Nav.Link className={`${headerStyles.padNav}`} href="https://phoenixconnection.es/work">{this.props.text.linkWork}<hr /></Nav.Link>
                <Nav.Link className={`${headerStyles.padNav}`} href="https://phoenixconnection.es/#team">{this.props.text.linkTeam}<hr /></Nav.Link>
                <Nav.Link className={`${headerStyles.padNav}`} href="https://blog.phoenixconnection.es">Blog<hr /></Nav.Link>
                <Nav.Link className={`${headerStyles.padNav}`} href="https://phoenixconnection.es/#contact">{this.props.text.linkContact}<hr /></Nav.Link>
              </ScrollSpy>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(Header)
