import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import WorkDesktopStyle from "../../styles/WorkDesktop.module.scss";
import { Parallax } from "react-scroll-parallax";
import { isBrowser } from "react-device-detect";
import '../../styles/App.scss';

class WorkDesktop extends React.Component {
  render() {
    const impar = (
      <>
        <Parallax y={[5, -5]}>
          <img alt={this.props.text.title} src={isBrowser
            ? this.props.text.parallaxImagesDesktop[0]
            : this.props.text.parallaxImagesTablet[0]
          }
            width={isBrowser ? 350 : 190}
            height={isBrowser ? 551 : 312}
          />
        </Parallax>
        <Parallax y={[-15, 15]}>
          <img alt={this.props.text.title} src={isBrowser
            ? this.props.text.parallaxImagesDesktop[1]
            : this.props.text.parallaxImagesTablet[1]
          }
            width={isBrowser ? 350 : 190}
            height={isBrowser ? 551 : 312}
          />
        </Parallax>
        <Parallax y={[-30, 30]}>
          <img alt={this.props.text.title} src={isBrowser
            ? this.props.text.parallaxImagesDesktop[2]
            : this.props.text.parallaxImagesTablet[2]
          }
            width={isBrowser ? 350 : 190}
            height={isBrowser ? 551 : 312}
          />
        </Parallax>
      </>
    );

    const par = (
      <>
        <Parallax y={[-30, 30]}>
          <img alt={this.props.text.title} src={isBrowser
            ? this.props.text.parallaxImagesDesktop[0]
            : this.props.text.parallaxImagesTablet[0]
          }
            width={isBrowser ? 350 : 190}
            height={isBrowser ? 551 : 312}
          />
        </Parallax>
        <Parallax y={[-15, 15]}>
          <img alt={this.props.text.title} src={isBrowser
            ? this.props.text.parallaxImagesDesktop[1]
            : this.props.text.parallaxImagesTablet[1]
          }
            width={isBrowser ? 350 : 190}
            height={isBrowser ? 551 : 312}
          />
        </Parallax>
        <Parallax y={[5, -5]}>
          <img alt={this.props.text.title} src={isBrowser
            ? this.props.text.parallaxImagesDesktop[2]
            : this.props.text.parallaxImagesTablet[2]
          }
            width={isBrowser ? 350 : 190}
            height={isBrowser ? 551 : 312}
          />
        </Parallax>
      </>
    );

    return (
      <div className={this.props.parImpar ? "whiteBackground" : ""}>
        <Container className={"padRight padLeft"}>
          <Row className={WorkDesktopStyle.workDesktopContainer}>
            <Col xs="12" className={this.props.parImpar ? WorkDesktopStyle.alignRight : WorkDesktopStyle.alignLeft}>
              <h1 id={this.props.idLink} className={WorkDesktopStyle.jobTitle}>{this.props.text.title}</h1>
              <br />
              <h1 className={`${WorkDesktopStyle.subTitleWork} 
                                ${this.props.parImpar ? WorkDesktopStyle.alignRight : WorkDesktopStyle.alignLeft} 
                                ${this.props.parImpar ? WorkDesktopStyle.subtitleRight : ""}`}
              >
                {this.props.text.subTitle}
              </h1>
              <br />
              <br />
            </Col>
            <Col md={8} lg={{ span: 6, offset: this.props.parImpar ? 3 : 0 }}
              className={`${WorkDesktopStyle.noSpaceLeft} 
                             ${this.props.parImpar ? WorkDesktopStyle.alignRight : WorkDesktopStyle.alignLeft}`}
            >
              <h5 className={WorkDesktopStyle.titleWork}>
                {this.props.workTitles.description}
              </h5>
              <p className={`${WorkDesktopStyle.descriptionWork} ${this.props.parImpar ? WorkDesktopStyle.margin24 : ''}`}>
                {this.props.text.description}
              </p>
            </Col>
            <Col md={4} lg={3} className={`${WorkDesktopStyle.noSpaceLeft} 
                            ${this.props.parImpar ? WorkDesktopStyle.alignRight : WorkDesktopStyle.alignLeft}`}
            >
              <h5 className={WorkDesktopStyle.titleWork}>
                {this.props.workTitles.services}
              </h5>
              <p className={`${WorkDesktopStyle.ServicesWork} ${this.props.parImpar ? WorkDesktopStyle.margin24 : ''}`}>
                {this.props.text.services.map((element, index) => {
                  return (
                    <div key={index}>
                      {element} <br />
                    </div>
                  )
                }
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={this.props.parImpar ? WorkDesktopStyle.mainImageLeft : WorkDesktopStyle.mainImageRight}>
              <img
                alt={this.props.text.title}
                src={isBrowser ? this.props.text.mainImageDesktop : this.props.text.mainImageTablet}
                width={"100%"}
                height={"100%"}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={WorkDesktopStyle.parallaxContainer}>
              {
                this.props.setup % 2 === 0
                  ? par
                  : impar
              }
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default WorkDesktop;
