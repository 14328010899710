import React from "react";
import jobSummaryStyle from "../../styles/JobSummary.module.scss";
import jobSummaryStyleTablet from "../../styles/JobSummaryTablet.module.scss";
import { ControlBar, Player } from 'video-react';
import { Col } from "react-bootstrap";
import '../../styles/App.scss';


class JobSummary extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      playerSource: this.props.info.video
    }
    this.play = this.play.bind(this);
    this.click = this.click.bind(this);
  }

  componentDidMount() {
    this.player.load();
  }

  play() {
    this.player.play();
  }

  click() {
  }

  render() {
    const componentePar = (
      <>
        <Col sm="12" md="12" lg="8" className={jobSummaryStyle.spaceTopText}>
          <div className={"job-video"} onMouseOver={this.play}>
            <Player
              className={jobSummaryStyle.player}
              ref={player => {
                this.player = player;
              }} fluid={false} muted={true} height={window.innerWidth > 1024 ? 555 : 414}
              width={window.innerWidth > 1024 ? 734 : 548}
            >
              <ControlBar disableCompletely={true} />
              <source src={this.props.info.video} />
            </Player>
          </div>
        </Col>
        <Col className={jobSummaryStyle.spaceWorksText_Par} md="12" lg="4" >
          <div className={jobSummaryStyle.spaceTopText}>
            <p className={`${jobSummaryStyle.titleWork} nn-Subheading`}>
              {this.props.info.jobTitle}
            </p>
            <h3 className={`${jobSummaryStyle.subTitleWork} nn-H3-regular`}>
              {this.props.info.jobSubtitle}
            </h3>

            <p className={`${jobSummaryStyleTablet.descriptionWork_TabletMov_Par} nn-Body`}>
              {this.props.info.description}
            </p>
            <a href={`work#${this.props.orderItem}linkWork`} className={`link nn-links`}>
              {this.props.info.link}
            </a>
          </div>
        </Col>
      </>
    );

    const componenteImpar = (
      <>
        <Col md="12" lg="4" className={jobSummaryStyle.spaceWorksText}>
          <p className={`${jobSummaryStyle.titleWork} nn-Subheading`}>
            {this.props.info.jobTitle}
          </p>
          <h3 className={`${jobSummaryStyle.subTitleWork} nn-H3-regular`}>
            {this.props.info.jobSubtitle}
          </h3>
          <p className={`${jobSummaryStyleTablet.descriptionWork_TabletMov_Impar} nn-Body`}>
            {this.props.info.description}
          </p>
          <a href={`work#${this.props.orderItem}linkWork`} className={`link nn-links`}>
            {this.props.info.link}
          </a>
        </Col>
        <Col md="12" lg="8">
          <div className={"job-video"} onMouseOver={this.play} style={{ float: "right" }}>
            <Player
              className={jobSummaryStyle.player}
              ref={player => {
                this.player = player;
              }} fluid={false} muted={true} height={window.innerWidth > 1024 ? 555 : 414}
              width={window.innerWidth > 1024 ? 734 : 548}
            >
              <ControlBar disableCompletely={true} />
              <source src={this.props.info.video} />
            </Player>
          </div>
        </Col>
      </>
    );
    return (
      <>
        {this.props.parImpar ? componentePar : componenteImpar}
      </>
    );
  }
}
export default JobSummary;
