import React from "react";
import HeaderBackground from "../../components/HeaderBackground";
import {Container, Row, Col,} from "react-bootstrap";
import Header from "../../components/Header";
import FooterBackground from "../../components/FooterBackground";
import MetaTags from "react-meta-tags";
import RecoveryPlan from "../../components/RecoveryPlan";
import DescriptionRecoveryButton from "../../components/DescriptionRecoveryButton";
import DescriptionRecovery from "../../components/DescriptionRecovery";
import TitleH2Recovery from "../../components/TitleH2Recovery";
import '../../styles/App.scss';
import ServiceRecovery from "../../components/ServiceRecovery";
import ContactForm from "../../components/ContactForm";
import RecoveryPlanForEUStyle from "../../styles/RecoveryPlanForEUPage.module.scss"
import {isBrowser} from "react-device-detect";
import ButtonRecovery from "../../components/ButtonRecovery";
import DesRecovery from "../../components/DesRecovery";

class RecoveryplanforEUPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meta: {
                title: "",
                description: ""
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.setState({
            meta:
                {
                    title: "Fondos COVID para Aceleración Digital | Phoenix Connection",
                    description: "En Phoenix Connection nos encantará ser tu partner tecnológico para ayudarte a invertir los fondos Covid y acelerar la digitalización de tu empresa"
                }
                });
    }

    componentWillUnmount() {
        this.props.handleLanguageChange("ES");
    }

    render() {
        const { meta } = this.state;
            return (
                <>
                    <MetaTags>
                        <title>
                            {meta.title}
                        </title>
                        <meta name="description" content={meta.description} />
                    </MetaTags>
                    <HeaderBackground color={"white"} />
                    <Container>
                        <Header handleLanguageChange={this.props.handleLanguageChange}
                                language={this.props.language}
                                text={this.props.languageText.header}
                                color={"white"}
                        />
                        <Row>
                            <Col  sm={12}>
                                <RecoveryPlan/>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={7} md={12} lg={{offset:5}}>
                                <DesRecovery
                                 description={this.props.languageText.recoveryDescription.description1}
                                 textButton={this.props.languageText.textButtons.button1}/>
                            </Col>
                        </Row>
                        {
                            isBrowser
                                ?<>
                                    <Row className={RecoveryPlanForEUStyle.spaceVertical}>
                                        <Col lg={6}>
                                            <DescriptionRecovery description={this.props.languageText.recoveryDescription.description2} />
                                        </Col>
                                        <Col className={RecoveryPlanForEUStyle.centerContentDescription} lg={6}>
                                            <TitleH2Recovery title={this.props.languageText.recoveryTitle.title1}/>
                                        </Col>
                                    </Row>
                                    <Row className={RecoveryPlanForEUStyle.spaceVertical}>
                                        <Col className={RecoveryPlanForEUStyle.centerContentDescription} lg={6}>
                                            <TitleH2Recovery title={this.props.languageText.recoveryTitle.title2}/>
                                        </Col>
                                        <Col lg={6}>
                                            <DescriptionRecovery description={this.props.languageText.recoveryDescription.description3}/>
                                        </Col>
                                    </Row>
                                    <Row className={RecoveryPlanForEUStyle.spaceVertical}>
                                        <Col lg={6}>
                                            <DescriptionRecovery
                                                description={this.props.languageText.recoveryDescription.description4}/>
                                        </Col>
                                        <Col className={`${RecoveryPlanForEUStyle.centerContentDescription} spaceButton`} lg={6}>
                                            <ButtonRecovery textButton={this.props.languageText.textButtons.button2}/>
                                        </Col>
                                    </Row>
                                    <Row className={RecoveryPlanForEUStyle.spaceVertical}>
                                        <Col lg={6}>
                                            <DescriptionRecovery description={this.props.languageText.recoveryDescription.description5}/>
                                        </Col>
                                        <Col className={RecoveryPlanForEUStyle.centerContentDescription} lg={6}>
                                            <TitleH2Recovery title={this.props.languageText.recoveryTitle.title3}/>
                                        </Col>
                                    </Row>
                                    <Row className={RecoveryPlanForEUStyle.spaceVertical}>
                                        <Col lg={6}>
                                            <DescriptionRecovery
                                                description={this.props.languageText.recoveryDescription.description6}/>
                                        </Col>
                                        <Col className={`${RecoveryPlanForEUStyle.spaceButtonCenter} spaceButton`} lg={6}>
                                            <ButtonRecovery textButton={this.props.languageText.textButtons.button3}/>
                                        </Col>
                                    </Row>
                                    <Row className={RecoveryPlanForEUStyle.spaceVertical}>
                                        <Col className={RecoveryPlanForEUStyle.centerContentDescription} lg={6}>
                                            <TitleH2Recovery title={this.props.languageText.recoveryTitle.title4}/>
                                        </Col>
                                        <Col lg={6}>
                                            <DescriptionRecovery description={this.props.languageText.recoveryDescription.description7}/>
                                        </Col>
                                    </Row>
                                    <Row className={RecoveryPlanForEUStyle.spaceVertical}>
                                        <Col className={RecoveryPlanForEUStyle.spaceDescriptionService} lg={{span:8}}>
                                            <DescriptionRecovery description={this.props.languageText.recoveryDescription.description8}/>
                                        </Col>
                                        <Col lg={{offset:6}}>
                                            <DescriptionRecovery description={this.props.languageText.recoveryDescription.description9}/>
                                        </Col>
                                    </Row>
                                    <Row className={RecoveryPlanForEUStyle.servicesCol}>
                                        <Col md={{offset:5}}>
                                            <ServiceRecovery
                                                services={this.props.languageText.servicesRecovery1}
                                            />
                                        </Col>
                                        <Col className={RecoveryPlanForEUStyle.spaceCol} md={{span:3}}>
                                            <ServiceRecovery
                                                services={this.props.languageText.servicesRecovery2}
                                            />
                                        </Col>
                                    </Row>
                                </>
                                : <>
                                    <Row>
                                        <Col sm={7} md={12} lg={{offset:5}}>
                                            <DescriptionRecovery description={this.props.languageText.recoveryDescription.description2}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={7} md={12}>
                                            <TitleH2Recovery title={this.props.languageText.recoveryTitle.title1}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={7} md={12}>
                                            <DescriptionRecovery description={this.props.languageText.recoveryDescription.description3}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <TitleH2Recovery title={this.props.languageText.recoveryTitle.title2}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={7} md={12} lg={{offset:4, span:4}}>
                                            <DescriptionRecoveryButton
                                                description={this.props.languageText.recoveryDescription.description4}
                                                textButton={this.props.languageText.textButtons.button2}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={7} md={12}>
                                            <DescriptionRecovery description={this.props.languageText.recoveryDescription.description5}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <TitleH2Recovery title={this.props.languageText.recoveryTitle.title3}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={7} md={12} lg={{offset:4, span:4}}>
                                            <DescriptionRecoveryButton
                                                description={this.props.languageText.recoveryDescription.description6}
                                                textButton={this.props.languageText.textButtons.button3}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={7} md={12}>
                                            <DescriptionRecovery description={this.props.languageText.recoveryDescription.description7}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <TitleH2Recovery title={this.props.languageText.recoveryTitle.title4}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={7} md={12}>
                                            <DescriptionRecovery description={this.props.languageText.recoveryDescription.description8}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={7} md={12}>
                                            <DescriptionRecovery description={this.props.languageText.recoveryDescription.description9}/>
                                        </Col>
                                    </Row>
                                    <Row className={RecoveryPlanForEUStyle.servicesCol}>
                                        <Col xs={12} md={{offset:2, span:3}}>
                                            <ServiceRecovery
                                                services={this.props.languageText.servicesRecovery1}
                                            />
                                        </Col>
                                        <Col className={`${RecoveryPlanForEUStyle.internalPaddingService} pt-3 pt-md-0`}
                                             xs={12} md={{offset:2, span:3}}
                                        >
                                            <ServiceRecovery
                                                services={this.props.languageText.servicesRecovery2}
                                            />
                                        </Col>
                                    </Row>
                                </>
                        }

                        <ContactForm
                            text={this.props.languageText.contactForm}
                            language={this.props.language}
                            legalAdvise={this.props.languageText.legalAdvise}
                            translation={this.props.languageText}
                            history={this.props.history}
                        />
                    </Container>
                    <FooterBackground text={this.props.languageText.footer} />
                </>
            );
    }
}

export default RecoveryplanforEUPage;
