import React from "react";
import {Row, Col, Container} from "react-bootstrap";
import RecoveryPlanStyle from "../../styles/RecoveryPlan.module.scss";
import '../../styles/App.scss';


class RecoveryPlan extends React.Component {

    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                             <h1 className='nn-H1-Light'><b className={'nn-H1-Bold'}>Fondo</b> de recuperación europeo para <b className={'nn-H1-Bold'}>la digitalización</b></h1>
                            <div className={RecoveryPlanStyle.RecoveryPlan_Box} />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default RecoveryPlan;
