import React from "react";
import { Row, Col } from "react-bootstrap";
import processStyles from "../../styles/ProcessComponent.module.scss";
import Lottie from "react-lottie";
import discoveryLottie from '../../lotties/Discovery.json';
import userExperienceLottie from '../../lotties/Explore.json';
import developLottie from '../../lotties/Develop.json';
import launchLottie from '../../lotties/Launch.json';
import VisibilitySensor from 'react-visibility-sensor';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from "react-gsap";
import { isBrowser } from "react-device-detect";


class ProcessComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: [],
      lottieSize: 0,
      pageX: 0
    }
  }

  componentDidMount() {
    let animationData = null;
    let sections = [];
    // eslint-disable-next-line array-callback-return
    this.props.text.sections.map((im, index) => {
      switch (im.key) {
        case "discovery":
          animationData = discoveryLottie;
          break;
        case "userExperience":
          animationData = userExperienceLottie;
          break;
        case "development":
          animationData = developLottie;
          break;
        case "launch":
          animationData = launchLottie;
          break;
        default:
          animationData = null;
          break;
      }
      sections.push({
        title: im.title,
        key: im.key,
        animationData,
        items: im.items
      });
    });
    let lottieSize = 288;
    const winWidth = window.innerWidth;
    if (winWidth >= 768 && winWidth < 991) {
      lottieSize = 236;
    } else if (winWidth < 768) {
      lottieSize = 144;
    }
    this.setState({ 'sections': sections, 'lottieSize': lottieSize });
    // document.addEventListener('wheel', this.handleScroll, { passive: false });
  }

  render() {
    const { sections, lottieSize } = this.state;
    const winWidth = window.innerWidth;

    if (winWidth < 767) {
      return (
        <div id='process' style={{backgroundColor: '#F8F8F8'}}>
          <Row className={`${processStyles.processContainer} padRight padLeft`}>
            <div className={`${processStyles.processBackground}`} id="conceptBackground" />
            <Col xs={12} className={processStyles.title}>
              {this.props.text.title}
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={12}>
                  {sections.map((im, index) => {
                    let defaultOptions = {
                      loop: true,
                      autoplay: true,
                      animationData: im.animationData
                    };

                    return (
                      <div className={`${processStyles.item} item`} key={`concept_group_${index}`}>
                        <Col xs={12} className={`${processStyles.conceptCard}`}>
                          <div id={im.key}>
                            <Col xs={12}>
                              <h2 style={{ marginLeft: 0 }}>{im.title}
                                <hr />
                              </h2>
                            </Col>
                            <Col xs={12}>
                              <Lottie
                                options={defaultOptions}
                                height={lottieSize}
                              />
                            </Col>
                            <Col xs={12} className={`${processStyles.conceptCard}`}>
                              <h3>
                                {im.items.map((it, index) => {
                                  return <span key={`concept_${index}`}>{it}</span>
                                })}
                              </h3>
                            </Col>
                          </div>
                        </Col>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div id='process' style={{backgroundColor: '#F8F8F8'}}>
          <Controller>
            <Scene triggerHook="onLeave" duration={400} offset={-100} pin >
              {(progress) => (
                <div className="controler">
                  <Row className={`${processStyles.processContainer} padRight padLeft`}>
                    <div className={`${processStyles.processBackground} `} id="conceptBackground" />
                    <Col xs={12} className={processStyles.title}>
                      {this.props.text.title}
                    </Col>
                    <Col xs={12} className={`offset-lg-1 px-1 `}>
                      <Row>
                        <Col xs={12} md={4} >
                          {sections.map((im, index) => {
                            let defaultOptions = {
                              loop: true,
                              autoplay: true,
                              animationData: im.animationData
                            };

                            return (
                              <div className={`${processStyles.item} item`} key={`concept_group_${index}`}>
                                <Col xs={12} className={`${processStyles.conceptCard}`}>
                                  <div id={im.key} className="elements"
                                    style={{
                                      display: index === 0 ? 'block' : 'none',
                                      opacity: index === 0 ? 1 : 0,
                                      transition: 'opacity 1.5s ease',
                                      position: 'absolute',
                                      left: isBrowser ? '200px' : '15px'
                                    }}>
                                    <Lottie
                                      options={defaultOptions}
                                      height={lottieSize}
                                      width={lottieSize}
                                    />
                                  </div>
                                </Col>
                              </div>
                            );
                          })}
                        </Col>

                        <Col xs={12} md={6}>
                          <div className={`${processStyles.processHorizontalScrollWrapper} itemsWrapper`} id="conceptWrapper">
                            {sections.map((im, index) => {
                              return (
                                <VisibilitySensor
                                  partialVisibility
                                  onChange={(isVisible) => {
                                    if (isVisible) {
                                      const svg = document.querySelectorAll(".elements");
                                      for (var i = 0; i < svg.length; i++) {
                                        const OpacityIn = id => {
                                          setTimeout(function () {
                                            document.getElementById(id).style.opacity = 1;
                                          }, 100);
                                        }
                                        const displayIn = id => {
                                          setTimeout(function () {
                                            document.getElementById(id).style.display = 'block';
                                            OpacityIn(id);
                                          }, 20);
                                        }
                                        const displayOut = id => {
                                          setTimeout(function () {
                                            document.getElementById(id).style.display = 'none';
                                          }, 300);
                                        }
                                        if (svg[i].id !== im.key) {
                                          displayOut(svg[i].id);
                                          document.getElementById(svg[i].id).style.opacity = 0;
                                        } else {
                                          displayIn(svg[i].id);
                                        };
                                      };
                                    };
                                  }}
                                >
                                  <Timeline totalProgress={progress} paused>
                                    <Tween
                                      to={{
                                        scrollTrigger: {
                                          trigger: '#process',
                                          start: "top top",
                                          end: 'bottom center',
                                          markers: false
                                        },
                                        y: -1080,
                                        duration: 5
                                      }}>
                                      <div className={`${processStyles.item} item`} key={`concept_group_${index}`} style={{ height: '370px' }}>
                                        <Row className="">
                                          <Col xs={12}>
                                            <h2 style={{ marginLeft: 0 }}>{im.title}
                                              <hr />
                                            </h2>
                                          </Col>
                                          <Col xs={12} className={`${processStyles.conceptCard}`}>
                                            <h3>
                                              {im.items.map((it, index) => {
                                                return <span key={`concept_${index}`}>{it}</span>
                                              })}
                                            </h3>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Tween>
                                  </Timeline>

                                </VisibilitySensor>
                              )
                            })}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}
            </Scene>
          </Controller>
        </div>
      )
    }
  };
}

export default ProcessComponent;
