import React from "react";
import {Row, Container} from "react-bootstrap";
import DescriptionRecoveryStyle from "../../styles/DescriptionRecoveryButton.module.scss";
import '../../styles/App.scss';
import {HashLink} from "react-router-hash-link";
import renderHTML from "react-render-html";
import { isBrowser, isTablet, isMobile } from "react-device-detect";


class ButtonRecovery extends React.Component {

    constructor() {
        super();
        this.state = {
            windowY: 0
        };
    }

    componentDidMount() {
        let YValue = 0;
        if(isBrowser) {
            YValue = -100;
        } else if (isTablet) {
            YValue = -50;
        } else if(isMobile) {
            YValue = 4500;
        }
        this.setState({textButton: renderHTML(`${this.props.textButton}`), windowY: YValue});
    }
    render() {
        const { windowY } = this.state;
        return (
            <Container>
                <Row>
                        <HashLink to={{
                            pathname: "/digitalizacion-servicios-fondo-recuperacion-europeo",
                            hash: "#contact"
                        }}onClick={() => setTimeout(() => window.scrollTo(window.scrollX, window.scrollY + windowY), 10)}>
                            <button className={DescriptionRecoveryStyle.buttonDescription}>
                                <b className="textButton">{this.state.textButton}</b>
                            </button>
                        </HashLink>
                </Row>
            </Container>
        );
    }
}

export default ButtonRecovery;