import React from "react";
import {Row, Col} from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import conceptsStyles from '../../styles/Concepts.module.scss';
import '../../styles/App.scss';

class Concepts extends React.Component {

    render() {
        return (
            <div className={conceptsStyles.conceptsContainer}>
                <div className={conceptsStyles.conceptsItems}>
                    <Row className={conceptsStyles.ourServices}>
                        <Col xs={12} sm={12}>
                        <ScrollAnimation animateIn="fadeInUp">
                            <p className={"nn-Subheading"}>{this.props.text.ourServices}</p>
                        </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={conceptsStyles.iterative} delay={1}>
                            <ScrollAnimation animateIn="fadeInUp">
                                {this.props.text.iterative}
                            </ScrollAnimation>
                        </Col>
                        <Col xs={12} className={conceptsStyles.design} delay={2}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <span className={conceptsStyles.de}>{this.props.text.de}</span> {this.props.text.design}
                            </ScrollAnimation>
                        </Col>
                        <Col xs={12} className={conceptsStyles.process} delay={3}>
                            <ScrollAnimation animateIn="fadeInUp" className={conceptsStyles.processText} >
                                {this.props.text.process}
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeIn">
                                <div className={conceptsStyles.barBox} />
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Concepts;