import React from "react";
import {Row, Col, Container} from "react-bootstrap";
import renderHTML from "react-render-html";
import '../../styles/App.scss';

class DescriptionRecovery extends React.Component {
    constructor(props) {
        super();
        this.state = {
            description: ""
        };
    }

    componentDidMount() {
        this.setState({description: renderHTML(`${this.props.description}`)});
    }

    render() {
        return (
                <Container>
                    <Row>
                        <Col>
                            {this.state.description}
                        </Col>
                    </Row>
                </Container>
        );
    }
}

export default DescriptionRecovery;
