import React from "react";
import ProcessComponent from "../ProcessComponent";


class ProcessContainer extends React.Component {
  render() {
    return (
      <ProcessComponent text={this.props.text} />
    );
  }
}

export default ProcessContainer;
