import React from "react";
import headerStyles from "../../styles/Header.module.scss";

function HeaderBackground(props) {

    return (
        <span className={props.color === "black" ? headerStyles.backgroundBlack : headerStyles.backgroundWhite} />
    );
}

export default HeaderBackground;