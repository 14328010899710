import React from "react";
import Lottie from "react-lottie";
import { Row, Col } from "react-bootstrap";
import renderHTML from 'react-render-html';
import animationData from '../../lotties/NN-icon-eyes.json';
import talentStyles from '../../styles/SearchTalent.module.scss';
import '../../styles/App.scss';

class SearchTalent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            size: "",
            rawSize: 0
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({ size: window.innerWidth <= 480 ? "mobile" : "desktop", rawSize: window.innerWidth });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    render() {
        const { size, rawSize } = this.state;
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData
        };
        return (
            <div className="container">
                <Row className={` ${talentStyles.talentSection} padRight padLeft`}>
                    <Col xs="12" md="2" lg="4" className={`${talentStyles.lottieCol} 
                ${(rawSize <= 768 && rawSize >= 480)
                            ? "offset-md-7"
                            : ""}`}>
                        <span className={talentStyles.lottie}>
                            <Lottie options={defaultOptions}
                                height={217}
                                width={217} />
                        </span>
                    </Col>
                    <Col xs="12" md="10" lg="6" className={`${talentStyles.seekingTalent} ${size === "desktop" ? "offset-lg-1" : ""}`} >
                        <h2>{this.props.text.talentTitle}</h2>
                        <p>{renderHTML(this.props.text.talentText)}</p>
                        <span className={talentStyles.talentMail}>{this.props.text.talentMail}</span>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SearchTalent;
