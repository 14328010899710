import React from "react";
import WorkTitleStyles from '../../styles/WorkTitle.module.scss';
import { Row, Col } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import '../../styles/App.scss';

class WorkTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            size: ""
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({size: window.innerWidth >= 991
                ? "desktop"
                : (window.innerWidth >= 480 && window.innerWidth <= 990)
                ? "tablet"
                : "mobile"
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }
    render() {
        const { size } = this.state;
        const leftPath = "/images/"+size+"-work-hero-left.png";
        const rightPath = "/images/"+size+"-work-hero-right.png";
        const parallaxLeftDesktop = (
            <Parallax className="workParallaxImage" y={[140,-50]} x={[25,25]}>
                <img src={leftPath} alt="Hero mobile" />
            </Parallax>
        );
        const parallaxRightDesktop = (
            <Parallax className="workParallaxImage" y={[-90,-40]} x={[60,60]}>
                <img src={rightPath} alt="Hero mobile" />
            </Parallax>
        );
        const parallaxLeftTablet = (
            <Parallax className="workParallaxImage" y={[30,-10]} x={[150,150]}>
                <img src={leftPath} alt="Hero mobile" />
            </Parallax>
        );
        const parallaxRightTablet = (
            <Parallax className="workParallaxImage" y={[-70,-40]} x={[270,270]}>
                <img src={rightPath} alt="Hero mobile" />
            </Parallax>
        );
        const parallaxLeftMobile = (
            <Parallax className="workParallaxImage" y={[30,-10]} x={[20,20]}>
                <img src={leftPath} alt="Hero mobile" />
            </Parallax>
        );
        const parallaxRightMobile = (
            <Parallax className="workParallaxImage" y={[-60,-40]} x={[140,140]}>
                <img src={rightPath} alt="Hero mobile" />
            </Parallax>
        );
        return (
            <Row className={WorkTitleStyles.componentContainer}>
                <Col xs={12} md={7} lg={6} className={WorkTitleStyles.workTitleContainer}>
                    <h5 className={`${WorkTitleStyles.smallTitle} nn-Subheading`}>{this.props.text.ourWork}</h5>
                    <h1 className={`${WorkTitleStyles.title} nn-H3-Light`}>
                        <span className={`${WorkTitleStyles.boldTitle} nn-H3-Bold`}>{this.props.text.weBuild} </span>
                        {this.props.text.experiences}
                        <span className={`${WorkTitleStyles.boldTitle} nn-H3-Bold`}> {this.props.text.forUsers}</span>
                        <div className={this.props.language === "EN" ? WorkTitleStyles.barBox : WorkTitleStyles.barBoxSpanish} />
                    </h1>
                    <p className={`${WorkTitleStyles.subTitle} nn-Body`}>
                        {this.props.text.subTitle}
                    </p>
                </Col>
                <Col xs={12} lg={6} className={WorkTitleStyles.workTitleParallaxContainer}>
                    {
                        size === "desktop"
                        ? parallaxLeftDesktop
                        : size === "tablet"
                        ? parallaxLeftTablet
                        : parallaxLeftMobile
                    }
                    {
                        size === "desktop"
                            ? parallaxRightDesktop
                            : size === "tablet"
                            ? parallaxRightTablet
                            : parallaxRightMobile
                    }
                </Col>
            </Row>
        );
    }
}

export default WorkTitle;
