import React from "react";
import { Col } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import valuesStyles from '../../styles/Values.module.scss';

class Values extends React.Component {
  render() {
    return (
      <div className={`${valuesStyles.valuesContainer} `} id="team" >
        <Col className={`${valuesStyles.team}`} >
          <ScrollAnimation animateIn="fadeInUp">
            <h2 className={valuesStyles.title}>
              {this.props.text.team}
              <hr />
            </h2>
            <p className={valuesStyles.description}>
              {this.props.text.teamText}
            </p>
          </ScrollAnimation>
        </Col>
        <Col className={`${valuesStyles.strength}`}>
          <ScrollAnimation animateIn="fadeInUp" delay={2}>
            <h2 className={valuesStyles.title}>
              {this.props.text.strength}
              <hr />
            </h2>
            <p className={valuesStyles.description}>
              {this.props.text.strengthText.split('\n').map(i => {
                return <span key={i}>{i}</span>
              })}
            </p>
          </ScrollAnimation>
        </Col>
        <Col className={`${valuesStyles.reality}`}>
          <ScrollAnimation animateIn="fadeInUp" delay={3}>
            <h2 className={valuesStyles.title}>
              {this.props.text.reality}
              <hr />
            </h2>
            <p className={valuesStyles.description}>
              {this.props.text.realityText.split('\n').map(i => {
                return <span key={i}>{i}</span>
              })}
            </p>
          </ScrollAnimation>
        </Col>
      </div>
    );
  }
}

export default Values;
