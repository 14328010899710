import React from "react";
import '../../styles/App.scss';
import '../../styles/EUDigitalKitProgram.module.scss'
import EUDigitalKitProgramStyle from "../../styles/EUDigitalKitProgram.module.scss";

class OfficialSponsors extends React.Component {
    constructor(props) {
        super();
        this.state = {};
    }

    render() {
        return (
            <img className={`${EUDigitalKitProgramStyle.officialSponsorsImg}`} src="/images/official-sponsors.svg" alt="Official sponsors" />
        );
    }
}

export default OfficialSponsors;
