import React from "react";
import HeaderBackground from "../../components/HeaderBackground";
import {Container} from "react-bootstrap";
import Header from "../../components/Header";
import FooterBackground from "../../components/FooterBackground";
import MessageReceived from "../../components/MessageReceived";

class MessageReceivedPage extends React.Component {
    componentDidMount() {
        const script = document.createElement('script');
        script.text = "gtag('event', 'conversion', {'send_to': 'AW-559268051/TQaiCKG9juIBENOB14oC'});"
        document.head.appendChild(script);
        const script2 = document.createElement('script');
        script2.text = "gtag('config', 'AW-559268051');"
        document.head.appendChild(script2);

        switch(this.props.language) {
            case "EN":
                document.title = "Message received! | Phoenix Connection";
                break;
            case "ES":
                document.title = "¡Mensaje recibido! | Phoenix Connection";
                break;
            default:
                document.title = "Message received! | Phoenix Connection";
                break;
        }
    }
    render() {
            return (
                <div>
                    <HeaderBackground color="black" />
                    <Container>
                        <Header handleLanguageChange={this.props.handleLanguageChange}
                                language={this.props.language}
                                text={this.props.languageText.header}
                                color="black"
                        />
                        <MessageReceived text={this.props.languageText.messageReceived} history={this.props.history} />
                    </Container>
                    <FooterBackground text={this.props.languageText.footer} />
                </div>
            );
    }
}

export default MessageReceivedPage;
