import React from "react";
import {Row, Col, Container} from "react-bootstrap";
import DescriptionRecoveryStyle from "../../styles/DescriptionRecoveryButton.module.scss";
import '../../styles/App.scss';
import {HashLink} from "react-router-hash-link";
import renderHTML from "react-render-html";
import { isBrowser, isTablet, isMobile } from "react-device-detect";

class DesRecovery extends React.Component {

    constructor() {
        super();
        this.state = {
            description: "",
            windowY: 0
        };
    }

    componentDidMount() {
        let YValue = 0;
        if(isBrowser) {
            YValue = -100;
        } else if (isTablet) {
            YValue = -50;
        } else if(isMobile) {
            YValue = 4500;
        }
        this.setState({description: renderHTML(`${this.props.description}`), windowY: YValue});
        this.setState({textButton: renderHTML(`${this.props.textButton}`), windowY: YValue});
    }
    render() {
        const { windowY } = this.state;
        return (
                <Container>
                    <Row>
                        <Col className={DescriptionRecoveryStyle.descriptionCol}>
                            {this.state.description}
                            <HashLink to={{
                                pathname: "/digitalizacion-servicios-fondo-recuperacion-europeo",
                                hash: "#contact"
                            }} onClick={() => setTimeout(() => window.scrollTo(window.scrollX, window.scrollY + windowY), 10)}>
                                <button className={DescriptionRecoveryStyle.buttonDescription}>
                                    <b className="textButton">{this.state.textButton}</b>
                                </button>
                            </HashLink>
                        </Col>
                    </Row>
                </Container>
        );
    }
}

export default DesRecovery;
