import React from "react";
import VideoMainPage from "../VideoMainPage/index";
import { isTablet, isBrowser, isMobile } from "react-device-detect";
import ContentVideoMainStyle from "../../styles/ContentVideoMain.module.scss";

class ContentVideoMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      srcVideo: this.props.text.videoDesk,
      loading: true
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.setState({ loading: false });
  }

  resize() {
    if (isBrowser) {
      this.setState(prevState => {
        return {
          ...prevState,
          width: 1110,
          height: 624.38,
          srcVideo: this.props.text.videoDesk
        }
      });
    }
    else if (isTablet) {
      this.setState(prevState => {
        return {
          ...prevState,
          width: 685,
          height: 385.32,
          srcVideo: this.props.text.videoDesk
        }
      });
    }
    else if (isMobile || window.innerWidth <= 480) {
      this.setState(prevState => {
        return {
          ...prevState,
          width: 310,
          height: 552.61,
          srcVideo: this.props.text.videoMov
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    const { width, height, srcVideo, loading } = this.state;
    return (
      <div className={ContentVideoMainStyle.ContentVideoMain}>
        {
          loading ? '' : <VideoMainPage width={width} height={height} src={srcVideo} />
        }
      </div>
    );
  }
}

export default ContentVideoMain;