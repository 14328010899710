import React from "react";
import {Row, Col, Container} from "react-bootstrap";
import '../../styles/App.scss';
import renderHTML from "react-render-html";

class TitleH2Recovery extends React.Component {
    constructor(props) {
        super();
        this.state = {
            title: ""
        };
    }

    componentDidMount() {
        this.setState({title: renderHTML(`${this.props.title}`)});
    }
    render() {
        return (
                <Container>
                    <Row>
                        <Col>
                            <p className='titleCenter'> {this.state.title}</p>
                        </Col>
                    </Row>
                </Container>
        );
    }
}

export default TitleH2Recovery;
