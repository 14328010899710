import React from 'react';
import './styles/App.scss';
import './styles/Media.scss';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import languageEN from './language/languageEN_US.json';
import languageES from './language/languageES.json';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { createBrowserHistory } from 'history';
import MainPage from './pages/MainPage';
import WorkPage from './pages/WorkPage';
import NotFoundPage from './pages/NotFoundPage';
import RecoveryPlanforEUPage from './pages/RecoveryPlanforEUPage';
import EUDigitalKitProgramPage from './pages/EUDigitalKitProgramPage';
import MessageReceivedPage from './pages/MessageReceivedPage';

import General from "./pages/General";

const history = createBrowserHistory();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageJSON: languageEN,
      language: "EN"
    };
  }

  handleLanguageChange = (language) => {
    switch (language) {
      case "EN":
        this.setState(
          prevState => {
            return {
              ...prevState,
              languageJSON: languageEN,
              language: "EN"
            }
          }
        );
        break;
      case "ES":
        this.setState(
          prevState => {
            return {
              ...prevState,
              languageJSON: languageES,
              language: "ES"
            }
          }
        );
        break;
      default:
    }
  }

  render() {
    const { languageJSON, language } = this.state;
    return (
      <ParallaxProvider>
        <Router history={history}>
          <Switch>
            <Route path="/" exact
              component={() => <MainPage
                languageText={languageJSON}
                language={language}
                history={history}
                handleLanguageChange={this.handleLanguageChange}
              />} />
            <Route path="/work"
              component={() => <WorkPage
                languageText={languageJSON}
                language={language}
                //    history={history}
                handleLanguageChange={this.handleLanguageChange}
              />} />
            <Route path="/messageReceived"
              component={() => <MessageReceivedPage
                languageText={languageJSON}
                language={language}
                history={history}
                handleLanguageChange={this.handleLanguageChange}
              />} />
            <Route path="/legalAdvise"
              component={() => <General
                type='legalAdvise'
                languageText={languageJSON}
                language={language}
                handleLanguageChange={this.handleLanguageChange}
              />} />
            <Route path="/cookiePolicy"
              component={() => <General
                type='cookiePolicy'
                languageText={languageJSON}
                language={language}
                handleLanguageChange={this.handleLanguageChange}
              />} />
            <Route path="/privacyPolicy"
              component={() => <General
                type='privacy_policy'
                languageText={languageJSON}
                language={language}
                handleLanguageChange={this.handleLanguageChange}
              />} />

            <Route path="/digitalizacion-servicios-fondo-recuperacion-europeo"
              component={() => <RecoveryPlanforEUPage
                languageText={languageJSON}
                language={language}
                history={history}
                handleLanguageChange={this.handleLanguageChange}
              />} />
            <Route path="/programa-kit-digital-fondos-europeos"
                   component={() => <EUDigitalKitProgramPage
                       languageText={languageJSON}
                       language={language}
                       history={history}
                       handleLanguageChange={this.handleLanguageChange}
                   />} />
            <Route
              component={() => <NotFoundPage
                languageText={languageJSON}
                language={language}
                history={history}
                handleLanguageChange={this.handleLanguageChange}
              />} />
          </Switch>
        </Router>
        <NotificationContainer />
      </ParallaxProvider>
    );
  }
}

export default App;
