import React from "react";
import WorkMobStyle from "../../styles/WorkMov.module.scss";
import {Col, Container, Row} from "react-bootstrap";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../../styles/App.scss';

class WorkMob extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      images: [],
    };
  }

  componentDidMount() {
    for (let i = 0; i < this.props.text.imagesMobile.length; i++) {
      this.state.images.push(
        <img
          key={this.props.text.imagesMobile[i].urlImage}
          className="d-block w-100"
          src={this.props.text.imagesMobile[i].urlImage}
          alt={this.props.text.imagesMobile[i].titleImage}
        />
      );
    }
  }
  render() {
    const { images } = this.state;
    const responsive = {
      0: { items: 1 }
    }
    return (
      <div className={this.props.parImpar ? "whiteBackground" : ""}>
        <Container className={"padRight padLeft"} style={{ paddingTop: "48px" }}>
          <Row className={WorkMobStyle.workMobileContainer}>
            <Col xs="12">
              <p className={`${WorkMobStyle.titleWork} nn-Subheading`}>{this.props.text.title}</p>
              <p className={WorkMobStyle.subTitleWork}>{this.props.text.subTitle}</p>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className={WorkMobStyle.workMobileCarousel}>
              <AliceCarousel
                  mouseTracking
                  items={images}
                  paddingLeft={25}
                  paddingRight={25}
                  responsive={responsive}
              />
            </Col>
          </Row>
          <Col xs="12" className={WorkMobStyle.noSpaceLeft}>
            <h5 className={`${WorkMobStyle.titleWork} nn-Subheading`} style={{ marginTop: 0 }}>
              {this.props.workTitles.description}
            </h5>
            <p className={`${WorkMobStyle.descriptionWork} nn-Body`}>{this.props.text.description}</p>
            <h5 className={`${WorkMobStyle.titleWork} nn-Subheading`} style={{ marginTop: 0 }}>
              {this.props.workTitles.services}
            </h5>
            <p className={`${WorkMobStyle.ServicesWork} nn-Small-txt`}>
              {this.props.text.services.map((element, index) => (
                <span key={index}>
                  {element} <br />
                </span>
              ))}
            </p>
          </Col>
        </Container>
      </div>
    );
  }
}

export default WorkMob;
