import React from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import axios from "axios";
import ContactFormStyles from '../../styles/ContactForm.module.scss';
import { NotificationManager } from "react-notifications";
import errors from '../../errors/errors.json';
import envConfig from '../../config.json';
import parse from "html-react-parser";
import { withRouter } from "react-router-dom";
import '../../styles/App.scss';

/*
    Expected props:
    text={languageEN.contactForm}
    language={language}
 */

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTermsModal: false,
      showPrivacityModal: false,
      name: "",
      surname: "",
      email: "",
      message: "",
      acceptedConditions: false,
      buttonDisabled: false,
      token: ""
    }
  }

  handleTermsModalShow = () => {
    this.setState({ showTermsModal: true });
  }

  handleTermsModalClose = () => {
    this.setState({ showTermsModal: false });
  }

  handleShowPrivacityModalShow = () => {
    this.setState({ showPrivacityModal: true });
  }

  handleShowPrivacityModalModalClose = () => {
    this.setState({ showPrivacityModal: false });
  }

  sendForm = event => {
    event.preventDefault();
    const { name, surname, email, message, acceptedConditions, token } = this.state;

    this.setState({ buttonDisabled: true });
    if (!acceptedConditions) {
      NotificationManager.error(errors[this.props.language].notAcceptedConditions, errors[this.props.language].notAcceptedConditionsTitle, 2000);
      this.setState({ buttonDisabled: false });
      return;
    }

    let form = new FormData();
    form.append("name", name);
    form.append("surname", surname);
    form.append("email", email);
    form.append("message", message);
    form.append("token", token);

    let url = window.location.href;
    let backUrl = "";

    if (url.indexOf('website.dev') !== -1) {
      backUrl = envConfig.PHOENIX_BACK_URL_DEV;
    } else if (url.indexOf('website.sta') !== -1) {
      backUrl = envConfig.PHOENIX_BACK_URL_STA;
    } else {
      backUrl = envConfig.PHOENIX_BACK_URL;
    }

    window.grecaptcha.ready(function () {
      window.grecaptcha.execute('6LcYQb4ZAAAAAM34ti2xbUrGLRXef4deQ0U-mqbm', { action: 'submit' }).then(function (token) {
        if (token.length !== 0) {
          axios.post(`${backUrl}/api/contact`, form)
            .then(res => {
              this.props.history.push('/messageReceived');
            })
            .catch(err => {
              if (err.response) {
                switch (err.response.status) {
                  case 500:
                    NotificationManager.error(errors[this.props.language].code500, errors[this.props.language].code500Title, 3000);
                    break;
                  case 400:
                    NotificationManager.error(errors[this.props.language].code400, errors[this.props.language].code400Title, 3000);
                    break;
                  case 403:
                    NotificationManager.error(errors[this.props.language].code403, errors[this.props.language].code403Title, 3000);
                    break;
                  default:
                    NotificationManager.error(errors[this.props.language].code500, errors[this.props.language].code500Title, 3000);
                    break;
                }
              }

              //this.setState({ buttonDisabled: false });
            });
        }
      })
    });

  }

  handleName = (value) => {
    this.setState(prevState => {
      return { ...prevState, name: value }
    });
  }

  handleSurname = (value) => {
    this.setState(prevState => {
      return { ...prevState, surname: value }
    });
  }

  handleEmail = (value) => {
    this.setState(prevState => {
      return { ...prevState, email: value }
    });
  }

  handleMessage = (value) => {
    this.setState(prevState => {
      return { ...prevState, message: value }
    });
  }

  handleConditions = () => {
    this.setState(prevState => {
      return { ...prevState, acceptedConditions: !prevState.acceptedConditions }
    });
  }

  render() {
    const { name, surname, email, message, buttonDisabled } = this.state;
    return (
      <div className="container">
        <div className={`lightGreyBackground ${ContactFormStyles.contactFormWrapper} 
                        ${window.location.href.indexOf('work') !== -1 ?
            ContactFormStyles.workContactFormWrapper : ""} 
                        ${window.location.href.indexOf('digitalizacion-servicios-fondo-recuperacion-europeo') !== -1 ?
            ContactFormStyles.RecoveryPlanContactFormWrapper : ""}                            
                    `} id={"contact"} />
        <Row className={`lightGreyBackground ${ContactFormStyles.contactFormBox} ${window.location.href.indexOf('work') !== -1 ? ContactFormStyles.workContactFormBox : ""}
                         ${window.location.href.indexOf('digitalizacion-servicios-fondo-recuperacion-europeo') !== -1 ?
            ContactFormStyles.RecoveryPlanContactFormBox : ""}`} >

          <Col xs={12} md={{ span: 5 }} lg={{ span: 3 }} className={ContactFormStyles.textContainer + " noPad"}>
            <h5 id={"contact-form"}>{this.props.text.contact}</h5>
            <h2 className={ContactFormStyles.title}>{this.props.text.workTogether}</h2>
            <p>{this.props.text.hear}</p>
            <p>{this.props.text.email}<br />{this.props.text.phone}</p>
            <p>{this.props.text.address}</p>
          </Col>
          <Col xs={12} md={12} lg={{ offset: 2 }} className={"noPad"}>
            <Form>
              <Form.Row className={ContactFormStyles.formRow}>
                <Col xs={12} md={4}>
                  <Form.Group controlId="NameData">
                    <Form.Label>{this.props.text.nameLabel}</Form.Label>
                    <Form.Control value={name} onChange={event => this.handleName(event.target.value)} type="text" placeholder={this.props.text.namePlaceholder} />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} className={ContactFormStyles.surnameBox}>
                  <Form.Group controlId="SurnameData">
                    <Form.Label>{this.props.text.surnameLabel}</Form.Label>
                    <Form.Control value={surname} onChange={event => this.handleSurname(event.target.value)} type="text" placeholder={this.props.text.surnamePlaceholder} />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className={ContactFormStyles.formRow}>
                <Col xs={12}>
                  <Form.Group controlId="EmailData">
                    <Form.Label>{this.props.text.emailLabel}</Form.Label>
                    <Form.Control value={email} onChange={event => this.handleEmail(event.target.value)} type="text" placeholder={this.props.text.emailPlaceholder} />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={12}>
                  <Form.Group controlId="MessageData">
                    <Form.Label>{this.props.text.messageLabel}</Form.Label>
                    <Form.Control value={message} onChange={event => this.handleMessage(event.target.value)} as="textarea" placeholder={this.props.text.messagePlaceholder} />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={12} lg={11}>
                  <Form.Group controlId="TermsAndPrivacy">
                    <label>
                      <Form.Check.Input onChange={this.handleConditions} type="checkbox" className={ContactFormStyles.checkInput} />
                      <span className={ContactFormStyles.checkInputLabel} >
                        <span className={ContactFormStyles.lightText}>{this.props.text.haveRead} </span>
                        <a className='textLink' onClick={this.handleTermsModalShow}>{this.props.text.legalAdvise} </a>
                        <span className="textLink">{this.props.text.and}</span>
                        <a className='textLink' onClick={this.handleShowPrivacityModalShow}>{this.props.text.privacyPolicy}</a>
                      </span>
                    </label>
                  </Form.Group>
                  <span style={{ fontSize: "10px", lineHeight: "12px", fontFamily: 'aktivLight' }}>{parse(this.props.text.privacyWarning)}</span>
                  <button disabled={buttonDisabled} className="phoenix-btn" onClick={event => this.sendForm(event)}>
                    {this.props.text.startConversation}
                  </button>
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        <Modal show={this.state.showTermsModal} onHide={this.handleTermsModalClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>
              <h1 style={{ textTransform: "uppercase" }}>{this.props.text.legalAdvise}</h1>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h5>{this.props.legalAdvise.title}</h5>
            {parse(this.props.translation.pages.legalAdvise.body)}
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showPrivacityModal} onHide={this.handleShowPrivacityModalModalClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>
              <h1 style={{ textTransform: "uppercase" }}>{this.props.text.privacyPolicy}</h1>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h5>{this.props.legalAdvise.title}</h5>
            {parse(this.props.translation.pages.privacy_policy.body)}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(ContactForm);
