/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Row, Col } from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import footerStyles from "../../styles/Footer.module.scss";

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            size: ""
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({size: window.innerWidth <= 991 ? "mobile" : "desktop"});
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    render() {
        const { size } = this.state;
            return (
                <div className={footerStyles.blackFooter}>
                    <Row>
                        <Col xs="12" sm="10" lg="3" className={`${footerStyles.footerBlock} ${footerStyles.footerLinks} ${size === "desktop" ? '': 'offset-sm-1'}`}>
                            <h4>{this.props.text.titleSocial}</h4>
                            <Row>
                                <Col xs="6" className={`${footerStyles.linkRS}`}>
                                    <a href="https://www.instagram.com/phoenix_connection_/" target="_blank" rel="noopener noreferrer">Instagram</a><br />
                                </Col>
                                <Col xs="6" className={`${footerStyles.linkRS}`}>
                                    <a href="https://www.linkedin.com/company/phoenix-connection-gm/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" lg="3" className={`${footerStyles.footerBlock}`}>
                            <h4>{this.props.text.titleContact}</h4>
                            <a href="mailto:soporte@phoenixconnection.es">soporte@phoenixconnection.es</a>
                        </Col>
                        <Col xs="12" lg="3" className={`${footerStyles.footerBlock}`}>
                            <h4>{this.props.text.titleCareers}</h4>
                            <a href="mailto:rrhh@phoenixconnection.es">rrhh@phoenixconnection.es</a>
                        </Col>
                        <Col xs="12" lg="3" className={`${footerStyles.footerBlock}`}>
                            <h4>{this.props.text.titleLinks}</h4>
                            <Link to="programa-kit-digital-fondos-europeos">Programa Kit Digital UE</Link><br/>
                            <Link to="digitalizacion-servicios-fondo-recuperacion-europeo">Fondo Recuperación UE</Link>
                        </Col>
                    </Row>
                    <Row className={footerStyles.footerElements}>
                            <Col xs="12" lg="5" className={`${size === "desktop" ? 'offset-md-5': ''}`}>
                                &copy; {(new Date().getFullYear())} Phoenix Connection <br className="d-block d-sm-none" />{`${this.props.text.rights} `}<br />
                                <Link to="cookiePolicy">{this.props.text.cookies}</Link> | <Link to="legalAdvise">{this.props.text.legalAdvise}</Link> | <Link to="privacyPolicy">{this.props.text.privacyPolicy}</Link>
                            </Col>
                            <Col xs="12" lg="2" className={`${footerStyles.phoenixLogoFooter}`}>
                                {
                                    size === "mobile"
                                    ? <img alt="phoenix-logo"  src="/logo/LOGO-ONELINE-WHITE.svg" />
                                    : <img alt="phoenix-logo" src="/logo/LOGO-RIGHT-WHITE.svg" />
                                }
                            </Col>
                    </Row>
                </div>
            );
    }
}

export default withRouter(Footer)
