import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import carouselStyles from '../../styles/TechCarousel.module.scss';
import '../../styles/verticalCarousel.scss';
import '../../styles/App.scss';

class TechCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: ""
    };
  }

  carousel = () => {
    return (
      <Col xs="12" md="8" lg="2" className="carouselContainer">
        <div className='carousel'>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>JAVA</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>SEM</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>C++</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>REACT</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>PHP</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>SEO</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>HTML5</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>SASS</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>.NET</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>AWS</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>Cloud</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>Drupal</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>MySQL</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>C#</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>Google</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>JQuery</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>Kotlin</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>IOS</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>GIT</span>
          </div>
          <div className='carousel__item'>
            <span className={carouselStyles.inactiveItem}>TFS</span>
          </div>
        </div>
      </Col>
    );
  }
  texts = () => {
    return (
      <Col xs="12" md="12" lg="7" className={`${carouselStyles.textContainer} padRight padLeft`}>
        <Row>
          <Col xs="12" className={`${carouselStyles.howWeWork} nn-Subheading`}>
            <p>{this.props.text.howWeWork}</p>
          </Col>
        </Row>
        <Row className={carouselStyles.MetodologiesSpace}>
          <Col xs="12" md="3" className={`${carouselStyles.technoMethodContainer} offset-lg-1`}>
            <h3 className={`${carouselStyles.technologies} nn-H3-Bold`}>{this.props.text.technologies}</h3>
            <br />
            <h3 className={`${carouselStyles.methodologies} nn-H3-Light`}>{this.props.text.methodologies}</h3>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="8" className={`${carouselStyles.description} nn-Body offset-sm-3 offset-md-3 offset-lg-4`}>
            <p>{this.props.text.description}</p>
          </Col>
        </Row>
      </Col>
    );
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ size: window.innerWidth <= 991 ? "mobile" : "desktop" });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  desktopView = () => {
    return (
      <>
        <div className={"fullWidthContainer BlackBackground padRight"} id="technologies">
          <Container className={"noPaddingTop"}>
            <div className={`${carouselStyles.techCarouselContainer} padRight padLeft`}>
              <Row>
                {this.texts()}
                {this.carousel()}
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }

  mobileView = () => {
    return (
      <div className={"fullWidthContainer BlackBackground"} id="technologies">
        <Container className={"noPaddingTop"}>
          <div className={carouselStyles.techCarouselContainer}>
            <Row>
              {this.carousel()}
              {this.texts()}
            </Row>
          </div>
        </Container>
      </div>
    );
  }

  render() {
    const { size } = this.state;
    return (
      <>
        {size === "mobile"
          ? this.mobileView()
          : this.desktopView()
        }
      </>
    );
  }
}

export default TechCarousel;
