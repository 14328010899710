import React from "react";
import { Row, Col } from "react-bootstrap";
import notFoundStyles from "../../styles/NotFound.module.scss";
import { withRouter, Link } from "react-router-dom";

function NotFoundMobile(props) {
    return(
        <Row className={notFoundStyles.notFoundContainer}>
            <Col xs="12" md="8" className={`${notFoundStyles.lottie} offset-md-4`} >
                <img alt="404" src="/images/404.svg"
                        height={props.screenSize <= 480 ? 270 : 394 }
                        width={props.screenSize <= 480 ? 305 : 430 }
                />
            </Col>
            <Col xs="12" md="8">
                <Col xs="12" className={notFoundStyles.titleContainer}>
                    <h2 className={notFoundStyles.title}>
                        <span className={notFoundStyles.boldTitle}>{props.text.boldTitle}</span>{props.text.title}
                        <hr />
                    </h2>
                </Col>
                <Col xs="12">
                    <p className={notFoundStyles.subTitle}>
                        {props.text.subTitle}
                    </p>
                </Col>
                <Col xs="12"className={notFoundStyles.buttonGroup}>
                    <Row>
                        <Col md="7" className={notFoundStyles.linksArea}>
                            <Link to="/">{props.text.homeLink}</Link>
                            <Link to={{
                                pathname: "/",
                                hash: "#process"
                            }}>{props.text.processLink}
                            </Link>
                            <Link to="/work">{props.text.workLink}</Link>
                        </Col>
                        <Col md="5" className={notFoundStyles.buttonContainer}>
                            <button className="phoenix-btn" onClick={() => handleBack(props.history)}>
                                {props.text.backButtonText}
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Col>
        </Row>
    );
}

const handleBack = (history) => {
    history.goBack();
}

export default withRouter(NotFoundMobile);
